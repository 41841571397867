import {BookingFormValues} from '../../../models/booking-wizard/BookingWizard'
import {BookingModelCreateParams} from '../../../models/ems/BookingModel'
import * as yup from 'yup'
import {EventModel} from '../../../models/ems/EventModel'
import 'moment-timezone'
import moment from 'moment'
import {DateUtil} from '../../../utils/DateUtil'

export const bookingWizardValidationSchema = yup.object().shape({
  eventCode: yup.string().required(),
  customer: yup.object().required(),
  products: yup
    .array()
    .test('has-atleast-one-product', 'Please add at least one product', (value, context) => {
      const formValues = context.parent as BookingFormValues

      if (
        formValues.products.length > 0 &&
        formValues.products.some((item) => item.data && item.count > 0)
      ) {
        return true
      }
      if (!value) {
        return false
      }

      return value.some((item) => {
        return Boolean(item.data) && item.count > 0
      })
    }),

  // vouchers: yup
  //   .array()
  //   .test('has-atleast-one-product', 'Please add at least one product', (value, context) => {
  //     const formValues = context.parent as BookingFormValues

  //     if (
  //       formValues.vouchers.length > 0 &&
  //       formValues.vouchers.some((item) => item.data && item.count > 0)
  //     ) {
  //       return true
  //     }
  //     if (!value) {
  //       return false
  //     }

  //     return value.some((item) => {
  //       return Boolean(item.data) && item.count > 0
  //     })
  //   }),

  customersSeats: yup
    .array()
    .test('has-atleast-one-seat', 'Please add at least one Seat', (value, context) => {
      const formValues = context.parent as BookingFormValues
      let productCount = 0
      for (const prod of formValues.products) {
        if (prod.bundleProducts?.length) {
          prod.bundleProducts.forEach((b) => {
            if (b.isSeated) {
              productCount++
            }
          })
        }
        if (prod.data?.isSeated) {
          productCount++
        }
      }
      // const filteredProducts = formValues.products.filter((item) => {
      //   if(item.b)
      // })
      // if (filteredProducts.length > 0) {
      //   if (value && filteredProducts.length === value.length) {
      //     return true
      //   }
      //   return false
      // }
      if (productCount && productCount !== value?.length) {
        return false
      }

      return true
    }),
})

export const EMPTY_FORM_VALUES: BookingFormValues = {
  customer: null,
  eventCode: '',
  products: [],
  vouchers: [],
  customersSeats: [],
  bundles: [],
}

export const getPayload = (
  values: BookingFormValues,
  event?: EventModel | null
): BookingModelCreateParams => {
  if (!values.customer || !event) {
    throw new Error('Invalid form data.')
  }

  const payload: BookingModelCreateParams = {
    customerCode: values.customer.code,
    eventCode: values.eventCode || '',
    products: [],
    bundles: [],
    vouchers: [],
  }
  values.products.forEach((item) => {
    if (item.data && item.count > 0) {
      if (item.bundleProducts?.length) {
        for (const bp of item.bundleProducts) {
          if (bp.isTimeslot && (!bp.startedAt || !bp.endedAt)) {
            throw new Error('Please add timeslots.')
          }
        }
        const bundleProducts = item.bundleProducts.map((bundle) => {
          let timeslotsArray: any = []
          let bundleActualQty = 0
          const found =
            bundle.isSeated && values.customersSeats && values.customersSeats?.length > 0
              ? values.customersSeats.find(
                  (seat) =>
                    seat.productCode === bundle?.code && item?.data?.code === seat.bundleCode
                )
              : null

          if (bundle.isTimeslot) {
            const _startDate = moment(moment(bundle.startedAt).toDate()).format(
              'YYYY-MM-DD HH:mm:ss'
            )
            const startedAt = DateUtil.convertDateTimeToVenueTimezoneToUTC(
              _startDate,
              `${event.venue?.timezone?.name}`
            )
            const _endDate = moment(moment(bundle.endedAt).toDate()).format('YYYY-MM-DD HH:mm:ss')
            const endedAt = DateUtil.convertDateTimeToVenueTimezoneToUTC(
              _endDate,
              `${event.venue?.timezone?.name}`
            )
            if (found) {
              return {
                code: bundle.code,
                qty: Number(bundle.bundleQty) || 0,
                locationCode: found?.locationCode,
                seats: found?.seats.getSeatMapObject(),
                startedAt: startedAt,
                endedAt: endedAt,
              }
            } else {
              if (bundle.bundleQty) {
                bundleActualQty = bundle.bundleQty * item.count
              } else {
                bundleActualQty = item.count
              }
              for (let i = 0; i < bundleActualQty; i++) {
                timeslotsArray.push({
                  startedAt,
                  endedAt,
                })
              }
              return {
                code: bundle.code,
                qty: Number(bundle.bundleQty) || 0,
                timeslots: timeslotsArray,
              }
            }
          } else {
            //seated non-timeslot
            if (found) {
              return {
                code: bundle.code,
                qty: Number(bundle.bundleQty) || 0,
                locationCode: found?.locationCode,
                seats: found?.seats.getSeatMapObject(),
              }
            } else {
              //non-seated non-timeslot
              return {
                code: bundle.code,
                qty: Number(bundle.bundleQty) || 0,
              }
            }
          }
        })

        payload.bundles.push({
          code: item.data.code,
          qty: item.count,
          products: bundleProducts || [{code: item.data.code, qty: item.count}],
        })
      } else {
        const found =
          item.data.isSeated && values.customersSeats && values.customersSeats?.length > 0
            ? values.customersSeats.find(
                (seat) => seat.productCode === item.data?.code && seat.bundleCode === undefined
              )
            : null
        if (item.data.isTimeslot) {
          if (item.data.isSeated) {
            const productOrCategoryStartDate = item.startDate
              ? item.startDate
              : item.data.productCategory?.startedAt
            const _startDate = moment(moment(productOrCategoryStartDate).toDate()).format(
              'YYYY-MM-DD HH:mm:ss'
            )
            const startedAt = DateUtil.convertDateTimeToVenueTimezoneToUTC(
              _startDate,
              `${event.venue?.timezone?.name}`
            )

            const productOrCategoryEndDate = item.endDate
              ? item.endDate
              : item.data.productCategory?.endedAt
            const _endDate = moment(moment(productOrCategoryEndDate).toDate()).format(
              'YYYY-MM-DD HH:mm:ss'
            )
            const endedAt = DateUtil.convertDateTimeToVenueTimezoneToUTC(
              _endDate,
              `${event.venue?.timezone?.name}`
            )

            payload.products.push(
              found
                ? {
                    code: item.data.code,
                    qty: item.count,
                    locationCode: found.locationCode,
                    seats: found.seats.getSeatMapObject(),
                    startedAt: startedAt,
                    endedAt: endedAt,
                  }
                : {code: item.data.code, qty: item.count}
            )
          } else {
            if (!item.timeslots?.length) {
              throw new Error('Please add start & end dates.')
            }
            let timeslotsArray: any = []
            const convertedTimeSlots = item.timeslots.map((time) => {
              const _startDate = moment(moment(time.startedAt).toDate()).format(
                'YYYY-MM-DD HH:mm:ss'
              )
              const _endDate = moment(moment(time.endedAt).toDate()).format('YYYY-MM-DD HH:mm:ss')
              time.startedAt = DateUtil.convertDateTimeToVenueTimezoneToUTC(
                _startDate,
                `${event.venue?.timezone?.name}`
              )
              time.endedAt = DateUtil.convertDateTimeToVenueTimezoneToUTC(
                _endDate,
                `${event.venue?.timezone?.name}`
              )
              return time
            })
            for (let i = 0; i < item.count; i++) {
              timeslotsArray.push(convertedTimeSlots[0])
            }
            payload.products.push({
              code: item.data.code,
              qty: item.count,
              timeslots: timeslotsArray,
            })
          }
        } else {
          payload.products.push(
            found
              ? {
                  code: item.data.code,
                  qty: item.count,
                  locationCode: found.locationCode,
                  seats: found.seats.getSeatMapObject(),
                }
              : {code: item.data.code, qty: item.count}
          )
        }
      }
    }
  })
  values.vouchers.forEach((item) => {
    if (item.data && item.count > 0) {
      payload.vouchers.push({code: item.data.code, qty: item.count})
    }
  })

  return payload
}

import {Route, Switch} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import {AuthFormLogoHeader, investopiaBG} from '../../../../config/logos'
import {Registration} from '../components/registration/Registration'
// import {useMemo} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

export const PARTNERS = [
  {
    src: toAbsoluteUrl('/media/saas/investopia-partners-3/Artboard 43.png'),
    alt: '',
  },
  {
    src: toAbsoluteUrl('/media/saas/investopia-partners-3/Artboard 44.png'),
    alt: '',
  },
  {
    src: toAbsoluteUrl('/media/saas/investopia-partners-3/Artboard 45.png'),
    alt: '',
  },
  {
    src: toAbsoluteUrl('/media/saas/investopia-partners-3/Artboard-83.png'),
    alt: 'Art46',
  },
  {
    src: toAbsoluteUrl('/media/saas/investopia-partners-3/Artboard 47.png'),
    alt: 'Artboard-47',
  },
  {
    src: toAbsoluteUrl('/media/saas/investopia-partners-3/Artboard 50.png'),
    alt: '',
  },
  {
    src: toAbsoluteUrl('/media/saas/investopia-partners-3/Artboard-51.png'),
    alt: 'Artboard-51',
  },
  {
    src: toAbsoluteUrl('/media/saas/investopia-partners-3/Artboard 52.png'),
    alt: '',
  },
  {
    src: toAbsoluteUrl('/media/saas/investopia-partners-3/Artboard 53.png'),
    alt: '',
  },
  {
    src: toAbsoluteUrl('/media/saas/investopia-partners-3/Artboard 54.png'),
    alt: '',
  },
  {
    src: toAbsoluteUrl('/media/saas/investopia-partners-3/Artboard 55.png'),
    alt: '',
  },
  {
    src: toAbsoluteUrl('/media/saas/investopia-partners-3/Artboard 56.png'),
    alt: '',
  },
]

export const RegistrationPage = () => {
  // const renderPartners = useMemo(() => {
  //   return PARTNERS.map((data, index) => (
  //     <div className='col-md-3 col-sm-3 col-3 px-0 d-flex justify-content-center' key={index}>
  //       <img
  //         alt={data.alt}
  //         src={data.src}
  //         className={`${
  //           data.alt === 'Art46' ? 'h-70px' : data.alt === 'Artboard-51' ? 'h-20px' : 'h-40px'
  //         } ${data.alt === 'Art46' ? 'mt-6' : 'mt-10'}`}
  //       />
  //     </div>
  //   ))
  // }, [])
  // const renderPartnersMobile = useMemo(() => {
  //   return PARTNERS.map((data, index) => (
  //     <div className='col-sm-4 col-4 px-0 d-flex justify-content-center' key={index}>
  //       <img
  //         alt={data.alt}
  //         src={data.src}
  //         className={`${
  //           data.alt === 'Art46'
  //             ? 'h-70px'
  //             : data.alt === 'Artboard-51'
  //             ? 'h-20px'
  //             : data.alt === 'Artboard-47'
  //             ? 'h-45px'
  //             : 'h-30px'
  //         } mt-10`}
  //       />
  //     </div>
  //   ))
  // }, [])

  return (
    <div
      style={{
        backgroundImage: `url(${investopiaBG.src})`,
        backgroundSize: 'cover',
        backgroundPosition: 'right',
        backgroundRepeat: 'no-repeat',
        height: '100%',
        width: '100%',
        // background: '#000c7d',
      }}
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom bgi-position-x-center bgi-repeat-x bgi-size-contain bgi-attachment-fixed'
    >
      <Helmet>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
        />
      </Helmet>
      <div className='d-flex flex-center flex-column flex-column-fluid pb-lg-20 min-h-100vh'>
        <img
          alt={AuthFormLogoHeader.alt}
          src={AuthFormLogoHeader.src}
          className='img-fluid my-8'
          style={{
            width: '250px',
          }}
        />
        <div className='blck-box w-md-600px w-sm-500px w-300px bg-body-custom rounded shadow-sm p-sm-10 p-5 p-lg-10 mx-auto'>
          <Switch>
            <Route path='/auth/register'>
              <Registration />
            </Route>
          </Switch>
        </div>
        {/* <div className='row align-content-center'>
          <div className='col-12 text-center mt-10'>
            <h2 className='text-white fs-1 custom-frm-inv'>PARTNERS</h2>
          </div>
        </div>
        <div className='d-none d-sm-flex gx-2 row align-items-center blck-box w-md-700px w-sm-600px w-300px '>
          {renderPartners}
        </div>
        <div className='d-flex d-sm-none gx-2 row align-items-center blck-box w-sm-600px w-400px '>
          {renderPartnersMobile}
        </div> */}

        {/* <div
          style={{justifyContent: 'space-between'}}
          className='blck-box w-md-700px w-sm-600px w-300px my-12 d-none d-sm-flex'
        >
          <div className='text-center travel-logo-left'>
            <h6 className='text-white custom-frm-inv'>EXCLUSIVE TRAVEL PARTNER</h6>
            <img
              alt={PoweredBySolaax.alt}
              src={toAbsoluteUrl('/media/saas/investopia-partners-3/Artboard-7-1.png')}
              className='mt-1 h-45px'
            />
          </div>
          <div className='text-center travel-logo-right'>
            <h6 className='text-white custom-frm-inv'>TECHNOLOGY PARTNER</h6>
            <img alt={PoweredBySolaax.alt} src={PoweredBySolaax.src} className='mt-3 h-20px' />
          </div>
          <div className='text-center col-12'>
            <img alt={PoweredBySolaax.alt} src={PoweredBySolaax.src} className='h-40px' />
          </div>
        </div> */}

        {/* <div className='d-block d-sm-none row w-md-600px w-sm-500px w-300px'>
          <div className='col-md-3 col-sm-4 text-center mt-10'>
            <h6 className='text-white custom-frm-inv'>EXCLUSIVE TRAVEL PARTNER</h6>
            <img
              alt={PoweredBySolaax.alt}
              src={toAbsoluteUrl('/media/saas/investopia-partners-3/Artboard-7-1.png')}
              className='mt-1 h-45px'
            />
          </div>
          <div className='col-md-3 col-sm-4'></div>
          <div className='col-md-3 d-sm-none d-md-block d-none'></div>
          <div className='col-12 d-block d-sm-none text-center my-10'>
            <h6 className='text-white custom-frm-inv'>TECHNOLOGY PARTNER</h6>
            <img alt={PoweredBySolaax.alt} src={PoweredBySolaax.src} className='h-30px' />
          </div>
        </div> */}
      </div>
    </div>
  )
}

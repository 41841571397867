import axios from 'axios'
import {FileInputValue} from '../../../../components/inputs/FileInput/FileInputValue'
import {
  CustomerModel,
  CustomerModelCreateFormData,
  CustomerModelTranslationCreateParams,
  CustomerServicesModel,
  GuestModelCreateFormDataParams,
} from '../../../../models/CustomerModel'
import {GuestLogModel} from '../../../../models/ems/GuestLogModel'
import {FileModel} from '../../../../models/FileModel'
import {FilterModel} from '../../../../models/FilterModel'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {GuestModel} from '../../../../models/GuestModel'
import {ISOCountryModel} from '../../../../models/ISOCountryModel'
import {
  CustomerCategoryModel,
  CustomerCategoryModelCreateParams,
  CustomerCategoryFeatureModel,
} from '../../../../models/svc/CustomerCategoryModel'
import {TemplateEmailTypeModel} from '../../../../models/svc/TemplateEmailTypeModel'
import {TemplateFieldModel} from '../../../../models/svc/TemplateFieldModel'
import {
  AssignTemplateCreateParams,
  TemplateModel,
  TemplateModelCreateParams,
} from '../../../../models/svc/TemplateModel'
import {
  TemplateSizeModel,
  TemplateSizeModelCreateParams,
  TemplateTypeModel,
  TemplateTypeModelCreateParams,
} from '../../../../models/svc/TemplateTypeModel'

import {VoucherModel, VoucherModelCreateParams} from '../../../../models/svc/VoucherModel'

import {
  NotificationModelParams,
  NotificationModel,
  NotificationTemplateModel,
} from '../../../../models/svc/NotificationModel'
import {
  NotificationModelAttributeModel,
  NotificationModelAttributeParams,
} from '../../../../models/svc/NotificationModelAttributeModel'
import {
  NotificationTriggerOperatorModel,
  NotificationTriggerModel,
  NotificationTriggerModelCreateParams,
  NotificationTTLModel,
} from '../../../../models/svc/NotificationTriggerModel'
import {EventModel} from '../../../../models/ems/EventModel'
import {WalletModel, WalletModelIOS, WalletPassTypeModel} from '../../../../models/svc/WalletModel'
import {WalletTemplateFieldModel} from '../../../../models/svc/WalletTemplateFieldModel'
import {CustomFieldCreateValue} from '../../../../models/system/CustomFieldModel'

const CRUD_CUSTOMER = '/customer'
const MASTER_SEARCH_CUSTOMER = '/customer/list'

export const ResetCustomerMFA = (customerCodes: string[]) =>
  axios.post(`/customer/reset-mfa`, {
    codes: customerCodes,
  })
export const GetCustomers = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<CustomerModel>>(MASTER_SEARCH_CUSTOMER, filter)
export const GetSpeakers = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<CustomerModel>>(MASTER_SEARCH_CUSTOMER, filter)
export const GetCustomerByCode = (code: string) =>
  axios.get<CustomerModel>(`${CRUD_CUSTOMER}/${code}`)
export const CreateOrUpdateCustomerTranslations = (
  customerCode: string,
  params: CustomerModelTranslationCreateParams
) => axios.post<CustomerModel>(`${CRUD_CUSTOMER}/translation/${customerCode}`, params)
export const GetCustomerTranslation = (code: string, lang: string) =>
  axios.get<CustomerModel>(`${CRUD_CUSTOMER}/${code}/${lang}`)
export const PostCustomer = (data: CustomerModelCreateFormData) =>
  axios.post(CRUD_CUSTOMER, data.getFormData(), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

export const GetCustomerPaByCode = (code: string) =>
  axios.get(`/personal-assistant${CRUD_CUSTOMER}/${code}`)

export const PostCustomerPa = (payload: FormData) => axios.post(`/personal-assistant`, payload)

export const PutCustomerPa = (customerCode: string, params: FormData) =>
  axios.put(`/personal-assistant/${customerCode}`, params)

export const PostCustomerSmartPin = (smartPin: string, codes: string[]) =>
  axios.post(`/customer/smart-pin`, {
    smartPin,
    codes,
  })
export const PostCustomerAccountCode = (accountCode: string, code: string) =>
  axios.post(`/customer/account-code`, {
    accountCode,
    code,
  })
export const ResetCustomerAccountCode = (codes: string[]) =>
  axios.post(`/customer/reset-account-code`, {
    codes,
  })

export const SendCustomerCustomEmail = (templateCode: string, customerCodes: string[]) =>
  axios.post(`/customer/send-manual-email`, {
    templateCode,
    codes: customerCodes,
  })

export const GetCustomerServicesByCustomerCode = (customerCode: string, payload: any) =>
  axios.post(`/customer-service${CRUD_CUSTOMER}/${customerCode}`, payload)
export const GetCustomerServices = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<CustomerServicesModel>>(`customer-service/list`, filter)
export const GetCustomerServiceByCode = (code: string) => axios.get(`/customer-service/${code}`)
export const PutCustomerService = (customerCode: string, payload: FormData) =>
  axios.put(`/customer-service/${customerCode}`, payload)
export const DeleteCustomerService = (code: string[] | string) =>
  axios.delete(`/customer-service`, {data: {codes: Array.isArray(code) ? code : [code]}})

export const PostCustomerFiles = async (data: FileInputValue[], customerCode: string) => {
  const formData = new FormData()
  data.forEach((file) => {
    const document = file.getFile()
    if (document) {
      formData.append('documents', document)
    }
  })
  await axios.put(`${CRUD_CUSTOMER}/documents/upload/${customerCode}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
export const DeleteCustomerFiles = async (fileCodes: string[], customerCode: string) => {
  await axios.delete(`${CRUD_CUSTOMER}/documents/${customerCode}`, {data: {codes: fileCodes}})
}

export const PutCustomer = (data: CustomerModelCreateFormData, customerCode: string) =>
  axios.put(`${CRUD_CUSTOMER}/${customerCode}`, data.getFormData(), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
export const DeleteCustomer = (customerCode: string[] | string) =>
  axios.delete(CRUD_CUSTOMER, {
    data: {codes: Array.isArray(customerCode) ? customerCode : [customerCode]},
  })
export const GetISOCountries = () => axios.get<ISOCountryModel[]>(`${CRUD_CUSTOMER}/country`)
export const ActivateCustomers = (customerCodes: string[]) =>
  axios.post(`${CRUD_CUSTOMER}/activate`, {codes: customerCodes})
export const SendRegistrationLink = (customerCodes: string[], eventCode: string) =>
  axios.post('/customer/send-registration', {
    codes: customerCodes,
    eventCode,
  })
export const GetRegistrationLink = (customerCode: string, eventCode: string) =>
  axios.post<{url: string}>(`/customer/registration/${customerCode}`, {
    eventCode,
    customerCode,
  })
export const SearchVoucherTemplates = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<VoucherModel>>(`/voucher/list/template`, filter)
export const SearchVouchers = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<VoucherModel>>(`/voucher/list`, filter)
export const CreateVoucher = (params: VoucherModelCreateParams) => axios.post(`/voucher`, params)
export const GetVoucherTopupHistory = () =>
  axios.get<Blob>(`/voucher/top-up`, {
    responseType: 'blob',
  })
export const GetVoucherByCode = (voucherCode: string) => axios.get(`/voucher/${voucherCode}`)
export const UpdateVoucher = (voucherCode: string, params: VoucherModelCreateParams) =>
  axios.put(`/voucher/${voucherCode}`, params)
export const DeleteVouchers = (voucherCodes: string[]) =>
  axios.delete(`/voucher`, {
    data: {
      codes: voucherCodes,
    },
  })
export const DisableVouchers = (voucherCodes: string[]) =>
  axios.post(`/voucher/disable`, {
    codes: voucherCodes,
  })
export const ActivateVoucher = (voucherCode: string, customerCode: string) =>
  axios.post(`/voucher/activate`, {voucherCode, customerCode})
export const PrintVoucher = (voucherCodes: string[]) =>
  axios.post(`/template/print/vouchers`, {codes: voucherCodes}, {responseType: 'blob'})
export const TopupVoucher = (voucherCode: string, amount: number) =>
  axios.post(`/voucher/top-up/${voucherCode}`, {amount})
export const CashoutVoucher = (voucherCode: string, amount: number, authCode: string) =>
  axios.post(`/voucher/cash-out/${voucherCode}`, {amount, authCode})

export const CreateImport = (mapping: string[], csv: File, hasHeaders: boolean = true) => {
  const formData = new FormData()
  formData.append('file', csv)
  mapping.forEach((map, index) => {
    formData.append(`map[${index}]`, map)
  })
  formData.append('hasHeaders', String(hasHeaders))
  return axios.post(`/import`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const DownloadVouchers = (voucherCode: string[]) =>
  axios.post<Blob>(
    `/voucher/download`,
    {codes: voucherCode},
    {
      responseType: 'blob',
    }
  )

export const MapCustomer = () => axios.get(`/import/map-customer`)
export const MapCustomerAddress = () => axios.get(`/import/map-customer-address`)
export const MapProduct = (eventCode: string) =>
  axios.post(`/import/map-product`, {event: eventCode})
export const MapBookingToEvent = (eventCode: string) =>
  axios.post(`/import/map-booking`, {event: eventCode})
export const SearchImports = (filter: FilterModel) => axios.post(`/import/list`, filter)
export const ResetImports = () => axios.delete(`/import`)

export const GetCustomerCategoryList = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<CustomerCategoryModel>>('/customer-category/list', filter)
export const GetCustomerCategoryByCode = (code: string) =>
  axios.get<CustomerCategoryModel>(`/customer-category/${code}`)
export const CreateCustomerCategory = (payload: CustomerCategoryModelCreateParams) =>
  axios.post(`/customer-category`, payload)
export const UpdateCustomerCategory = (code: string, payload: CustomerCategoryModelCreateParams) =>
  axios.put(`/customer-category/${code}`, payload)
export const DeleteCustomerCategories = (codes: string[]) =>
  axios.delete(`/customer-category`, {
    data: {
      codes,
    },
  })
export const SetCategoryAsDefault = (code: string) =>
  axios.post(`/customer-category/default/${code}`)
export const UnsetCategoryAsDefault = (code: string) =>
  axios.post(`/customer-category/remove-default/${code}`)
export const GetCustomerCategoryTree = () =>
  axios.get<CustomerCategoryModel[]>(`/customer-category/tree`,)
export const GetEventCustomerCategoryTree = (eventCode?: string) =>
  axios.get<CustomerCategoryModel[]>(`/customer-category/tree/${eventCode}`,)


export const EventGetCustomerCategoryList = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<CustomerCategoryModel>>('/customer-category/list', filter)
export const EventGetCustomerCategoryByCode = (code: string) =>
  axios.get<CustomerCategoryModel>(`/customer-category/${code}`)
export const EventCreateCustomerCategory = (payload: CustomerCategoryModelCreateParams) =>
  axios.post(`/customer-category`, payload)
export const EventUpdateCustomerCategory = (code: string, payload: CustomerCategoryModelCreateParams) =>
  axios.put(`/customer-category/${code}`, payload)
export const EventDeleteCustomerCategories = (codes: string[]) =>
  axios.delete(`/customer-category`, {
    data: {
      codes,
    },
  })
export const EventSetCategoryAsDefault = (code: string) =>
  axios.post(`/customer-category/default/${code}`)
export const EventUnsetCategoryAsDefault = (code: string) =>
  axios.post(`/customer-category/remove-default/${code}`)
export const EventGetCustomerCategoryTree = () =>
  axios.get<CustomerCategoryModel[]>(`/customer-category/tree`)

export const CreateEventGuest = (
  email: string,
  firstName: string,
  lastName: string,
  eventCode: string,
  productCode?: string,
  customerCode?: string,
  guestCustomFields?: CustomFieldCreateValue[]
) =>
  axios.post(`/guest`, {
    email,
    firstName,
    lastName,
    eventCode,
    productCode,
    customerCode,
    guestCustomFields,
  })

export const PutGuest = (data: GuestModelCreateFormDataParams, customerCode: string) =>
  axios.put(`/guest/${customerCode}`, data)

export const SendGuestCustomEmail = (templateCode: string, guestCodes: string[]) =>
  axios.post(`/guest/send-manual-email`, {
    templateCode,
    codes: guestCodes,
  })

export const AssignGuestCategory = (eventCode: string, productCode: string, guestCodes: string[]) =>
  axios.post(`/guest/assign`, {
    eventCode,
    productCode,
    codes: guestCodes,
  })
export const ReassignGuestCategory = (
  eventCode: string,
  productCode: string,
  customerCodes: string[],
  reason: string
) =>
  axios.post(`/guest/re-assign`, {
    eventCode,
    productCode,
    codes: customerCodes,
    reason,
  })

export const AssignGuestGroup = (eventCode: string, guestCategoryCode: string, guestCodes: string[]) =>
  axios.post(`/guest/assign-category`, {
    eventCode,
    guestCategoryCode,
    codes: guestCodes,
  })
export const RemoveGuestGroup = (
  eventCode: string,
  customerCodes: string[],
) =>
  axios.post(`/guest/remove-category`, {
    eventCode,
    codes: customerCodes,
  })
export const ConfirmGuests = (eventCode: string, guestCodes: string[]) =>
  axios.post(`/guest/confirm`, {
    eventCode,
    codes: guestCodes,
  })
export const CancelGuests = (eventCode: string, reason: string, guestCodes: string[]) =>
  axios.post(`/guest/cancel`, {
    eventCode,
    reason,
    codes: guestCodes,
  })
export const SendGuestRegistration = (eventCode: string, guestCodes: string[]) =>
  axios.post(`/guest/send-registration`, {
    eventCode,
    codes: guestCodes,
  })
export const GetGuestRegistrationLink = (guestCode: string) =>
  axios.get<{url: string}>(`/guest/registration-link/${guestCode}`)
export const SendGuestRsvp = (eventCode: string, guestCodes: string[]) =>
  axios.post(`/guest/send-rsvp`, {
    eventCode,
    codes: guestCodes,
  })
export const AssignGuestSeat = (
  guestCode: string,
  locationCode: string,
  seatRow: string,
  seatNo: string | number
) =>
  axios.put(`/guest/assign-seat/${guestCode}`, {
    locationCode,
    seatRow,
    seatNo: String(seatNo),
  })
export const GetGuestList = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<GuestModel>>(`/guest/list`, filters)

export const PostGuestReport = (columns: string[], filters: any, sortField?: string, sortOrder?: string, ) =>
  axios.post(`/guest/export`, {filters, sortField, sortOrder, columns},  {responseType: 'blob'})

export const GetGuestByCode = (code: string) => axios.get<GuestModel>(`/guest/${code}`)
export const SearchGuestLogs = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<GuestLogModel>>(`/log/guest`, filters)
export const PrintGuestBadges = (guestCodes: string[]) =>
  axios.post<Blob>(
    `/template/print/guests`,
    {
      codes: guestCodes,
    },
    {
      responseType: 'blob',
    }
  )
export const ReprintGuestBadges = (guestCodes: string[], reason: string) =>
  axios.post<Blob>(
    `/template/reprint/guests`,
    {
      codes: guestCodes,
      reason,
    },
    {
      responseType: 'blob',
    }
  )
export const ActivateGuestCard = (rfid: string, guestCode: string) =>
  axios.post(`/guest/activate-card`, {
    code: guestCode,
    rfid,
  })

export const GetTemplateEmailTypes = () =>
  axios.get<TemplateEmailTypeModel[]>(`/template/email-types`)
export const GetFieldsByEmailTypeCode = (type: string, emailTypeCode?: string) =>
  axios.post<TemplateFieldModel[]>(`/template/fields`, {type, emailTypeCode})
export const GetTemplateByCode = (code: string) => axios.get<TemplateModel>(`/template/${code}`)
//Used when getting all templates that are manual for custom email
export const SearchTemplates = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<TemplateModel>>(`/template/list`, filters)
//Templates with eventId = null and assigned to multiple events
//Used in MasterData templates table
export const SearchTemplatesMasterData = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<TemplateModel>>(`/template/event-list`, filters)
//Used in Event templates table
export const SearchTemplatesByEvent = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<TemplateModel>>(`/template/event-all`, filters)
export const SearchUnassignedTemplatesByEvent = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<TemplateModel>>(`/template/event-templates`, filters)
export const CreateTemplate = (data: TemplateModelCreateParams) =>
  axios.post<TemplateModel>(`/template`, data)
export const UpdateTemplate = (code: string, data: TemplateModelCreateParams) =>
  axios.put<TemplateModel>(`/template/${code}`, data)
export const DeleteTemplates = (codes: string[], eventCode?: string) =>
  axios.delete<TemplateModel>(`/template`, {
    data: {
      codes,
      eventCode,
    },
  })
export const AssignTemplateToEvent = (params: AssignTemplateCreateParams) => {
  axios.post<AssignTemplateCreateParams>(`template/assign-template`, params)
}
export const SearchTemplateTypes = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<TemplateTypeModel>>(`template-type/list`, filters)
export const CreateTemplateType = (data: TemplateTypeModelCreateParams) =>
  axios.post<TemplateTypeModel>(`/template-type`, data)
export const UpdateTemplateType = (code: string, data: TemplateTypeModelCreateParams) =>
  axios.put<TemplateTypeModel>(`/template-type/${code}`, data)
export const DeleteTemplateTypes = (codes: string[]) =>
  axios.delete<TemplateTypeModel>(`/template-type`, {
    data: {codes},
  })
export const GetTemplateTypeByCode = (code: string) =>
  axios.get<TemplateTypeModel>(`/template-type/${code}`)
export const GetTemplateTypes = () => axios.get<TemplateTypeModel[]>(`/template-type/all`)

export const SearchTemplateSizes = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<TemplateSizeModel>>(`/template-size/list`, filters)
export const CreateTemplateSize = (data: TemplateSizeModelCreateParams) =>
  axios.post<TemplateSizeModel>(`/template-size`, data)
export const UpdateTemplateSize = (code: string, data: TemplateSizeModelCreateParams) =>
  axios.put<TemplateSizeModel>(`/template-size/${code}`, data)
export const DeleteTemplateSizes = (codes: string[]) =>
  axios.delete<TemplateSizeModel>(`/template-size`, {
    data: {codes},
  })
export const GetTemplateSizeByCode = (code: string) =>
  axios.get<TemplateSizeModel>(`/template-size/${code}`)

export const SearchTemplateFonts = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<FileModel>>(`/font-file/list`, filters)
export const UploadTemplateFontFile = (fontFile: Blob | File) => {
  const formData = new FormData()
  let name: string | undefined = undefined
  if (fontFile instanceof File) {
    name = encodeURIComponent(fontFile.name.replaceAll(' ', '-'))
  }
  formData.append('fontFile', fontFile, name)

  return axios.post<FileModel[]>(`/font-file/upload`, formData)
}
export const DeleteTemplateFonts = (codes: string[]) => axios.delete(`/font-file`, {data: {codes}})

export const SearchTemplateImages = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<FileModel>>(`/template-image/list`, filters)
export const UploadTemplateImageFile = (image: Blob) => {
  const formData = new FormData()
  formData.append('templateImage', image)
  return axios.post<FileModel[]>(`/template-image/upload`, formData)
}
export const DeleteTemplateImages = (codes: string[]) =>
  axios.delete(`/template-image`, {data: {codes}})

export const GetNotificationModel = (data: NotificationModelParams) =>
  axios.post<NotificationModel[]>(`/notification-model/list`, data)
export const GetNotificationAttribute = (data: NotificationModelAttributeParams) =>
  axios.post<NotificationModelAttributeModel[]>(`/notification-model/attributes`, data)
// export const GetNotificationOperator = (filters?: FilterModel) =>
//   axios.post<Array<NotificationTriggerOperatorModel>>(`/notification-trigger/operator/list`, filters)
export const GetNotificationOperator = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<NotificationTriggerOperatorModel>>(
    `/notification-trigger/operator/list`,
    filters
  )
export const GetNotificationTrigger = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<NotificationTriggerModel>>(`/notification-trigger/list`, filters)
export const GetNotificationTriggerByCode = (code?: string) =>
  axios.get<NotificationTriggerModel>(`/notification-trigger/${code}`)
export const GetEMSNotificationList = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<NotificationTemplateModel>>(`/ems-notification/list`, filters)
export const GetEmailNotificationList = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<NotificationTemplateModel>>(`/template/list`, filters)
export const CreateNotificationTrigger = (data: NotificationTriggerModelCreateParams) =>
  axios.post<NotificationTriggerModel>(`/notification-trigger`, data)
export const RemoveNotificationTrigger = (code: string[] | string) =>
  axios.delete(`/notification-trigger`, {data: {codes: Array.isArray(code) ? code : [code]}})
export const UpdateNotificationTrigger = (
  data: NotificationTriggerModelCreateParams,
  code?: string
) => axios.put<NotificationTriggerModel>(`/notification-trigger/${code}`, data)
export const GetTTL = () => axios.get<NotificationTTLModel[]>(`/notification-trigger/ttl`)
export const ManualTriggerNotification = (code: string) =>
  axios.post<NotificationTriggerModel>(`/notification-trigger/manual/${code}`)
export const GetEventByCode = (code: string) => axios.get<EventModel>(`/event/${code}`)

export const SearchWalletGoogleFields = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<WalletTemplateFieldModel>>(`/wallet-template-field/list`, filters)
export const GetWalletPassTypes = () =>
  axios.get<WalletPassTypeModel[]>(`/wallet-template-type/pass`)

export const SearchWalletList = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<WalletModel>>(`/wallet-template/list`, filters)
export const DeleteWalletByCode = (code: string[] | string) =>
  axios.delete(`/wallet-template`, {data: {codes: Array.isArray(code) ? code : [code]}})
export const CreateGoogleWallet = (formData: FormData) => axios.post('/wallet-template', formData)
export const UpdateGoogleWallet = (formData: FormData, code: string) =>
  axios.put(`/wallet-template/${code}`, formData)
export const GetWalletByCode = (code: string) => axios.get<WalletModel>(`/wallet-template/${code}`)

export const GetCustomerCategoryFeatureCodes = () =>
  axios.get<CustomerCategoryFeatureModel[]>(`/customer-category/features`)

export const GetWalletByCodeIOS = (code: string) =>
  axios.get<WalletModelIOS>(`/wallet-template/${code}`)

export const CustomizeVoucherCode = (customCode: string, customerCode: string) =>
  axios.post(`/voucher/customize-code/${customerCode}`, {customCode})

import {FormikContextType} from 'formik'
import {useCallback} from 'react'
import {TextInputProps} from '../TextInput'

export type UseFormikTextInputHelpersProps = Pick<
  TextInputProps,
  'errorMessage' | 'isTouched' | 'onBlur' | 'onChange' | 'value'
>
export const useFormikTextInputHelpers = (formik: FormikContextType<any>) => {
  const getProps = useCallback(
    (name: string): UseFormikTextInputHelpersProps => {
      return {
        ...formik.getFieldProps(name),
        errorMessage: formik.errors[name] as string,
        // isTouched: formik.touched[name] as boolean,
        isTouched: formik.values[name] ? true : (formik.touched[name] as boolean),
      }
    },
    [formik]
  )

  return {
    getProps,
  }
}

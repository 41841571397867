import axios from 'axios'
import {SeatMapValueObject} from '../../../../components/inputs/SeatMapInput/SeatMapValue'
import {FilterModel} from '../../../../models/FilterModel'
import {GateModel, GateModelCreateParams} from '../../../../models/acs/GateModel'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {LocationModel, LocationModelCreateParams} from '../../../../models/acs/LocationModel'
import {SeatMapModelCreateParams} from '../../../../models/SeatMapModel'
import {
  VenueModel,
  VenueModelCreateParams,
  VenueModelTranslation,
  VenueModelTranslationCreateParams,
} from '../../../../models/acs/VenueModel'
import {DateUtil} from '../../../../utils/DateUtil'
import {TimeZoneModel} from '../../../../models/acs/TimezoneModel'
import {IndustryModel} from '../../../../models/CustomerModel'

const CRUD_GATE = '/gate'
const CRUD_LOCATION = '/location'
const CRUD_VENUE = '/venue'
const ACS_PARENT_GATE = '/gate/parent'
const ACS_PARENT_LOCATION = '/location/parent'
const ACS_PARENT_LOCATION_VENUE = '/location/venue'
const ACS_PARENT_VENUE = '/venue/parent'
const ACS_PARENT_VENUE_TREE = '/venue/tree'
const ACS_SEARCH_GATE = '/gate/list'
const ACS_SEARCH_LOCATION = '/location/list'
const ACS_SEARCH_VENUE = '/venue/list'
const ACS_SEARCH_TIMEZONES = '/timezone/list'
const CRUD_CUSTOMER = '/customer'

const DeleteGates = (codes: string[]) => axios.delete('/gate', {data: {codes}})
const DeleteLocations = (codes: string[]) => axios.delete(CRUD_LOCATION, {data: {codes}})
const DeleteVenues = (codes: string[]) => axios.delete(CRUD_VENUE, {data: {codes}})

const GetParentGates = (filter?: FilterModel) => axios.post(ACS_PARENT_GATE, filter)
const GetParentLocations = (filter?: FilterModel) =>
  axios.post<LocationModel[]>(ACS_PARENT_LOCATION, filter)
const GetParentLocationByVenue = (filter?: FilterModel) =>
  axios.post<VenueModel[]>(ACS_PARENT_LOCATION_VENUE, filter)
const GetParentVenues = (filter?: FilterModel) => axios.post<VenueModel[]>(ACS_PARENT_VENUE, filter)
const GetParentVenueTree = (filter?: FilterModel) =>
  axios.post<VenueModel[]>(ACS_PARENT_VENUE_TREE, filter)
const GetGates = (filter?: FilterModel) => axios.post(ACS_SEARCH_GATE, filter)
const GetLocations = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<LocationModel>>(ACS_SEARCH_LOCATION, filter)
const GetLocationByCode = (locationCode: string) =>
  axios.get<LocationModel>(`${CRUD_LOCATION}/${locationCode}`)
const GetLocationTranslations = (code: string, lang: string) =>
  axios.get<LocationModel>(`${CRUD_LOCATION}/${code}/${lang}`)
const CreateOrUpdateLocationTranslation = (
  code: string,
  params: VenueModelTranslationCreateParams
) => axios.post<VenueModelTranslation>(`${CRUD_LOCATION}/translation/${code}/`, params)
const GetLocationByEventCode = (eventCode: string) =>
  axios.get<LocationModel[]>(`${CRUD_LOCATION}/event/seat-map/${eventCode}`)
const GetLocationsByProductCode = (productCode: string) =>
  axios.get<LocationModel[]>(`/product/${productCode}/location`)
const GetDefaultLocationByCode = (locationCode: string) =>
  axios.get<LocationModel>(`${CRUD_LOCATION}/${locationCode}/default`)
const GetGateLocationByCode = (locationCode: string) =>
  axios.get<LocationModel[]>(`${CRUD_LOCATION}/${locationCode}/gate`)
const GetEntranceLocationByCode = (locationCode: string) =>
  axios.get<LocationModel[]>(`${CRUD_LOCATION}/${locationCode}/entrance`)
const ExportLocations = (venueCode: string) =>
  axios.post<Blob>(
    `/location/venue/export`,
    {
      venue: venueCode,
    },
    {
      responseType: 'blob',
    }
  )

const GetGateScanner = (options: {type?: string; location?: string}) =>
  axios.post<GateModel[]>(`${CRUD_GATE}/scanner`, options)
const GetVenues = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<VenueModel>>(ACS_SEARCH_VENUE, filter)
const GetVenueByCode = (code: string) => axios.get<VenueModel>(`${CRUD_VENUE}/${code}`)
const GetVenueTranslations = (code: string, lang: string) =>
  axios.get<VenueModel>(`${CRUD_VENUE}/${code}/${lang}`)
const CreateOrUpdateVenueTranslation = (code: string, params: VenueModelTranslationCreateParams) =>
  axios.post<VenueModelTranslation>(`${CRUD_VENUE}/translation/${code}/`, params)

const GetOccupiedSeatMaps = (locationCode: string, eventCode: string, date: Date) =>
  axios.post<SeatMapValueObject>(`${CRUD_LOCATION}/used-seats`, {
    location: locationCode,
    event: eventCode,
    date: DateUtil.convertDateToApiString(date),
  })
const GetOccupiedSeatMapsByReservation = (locationCode: string, payload: any) =>
  axios.post<SeatMapValueObject>(
    `${CRUD_LOCATION}/${locationCode}/seatmap/reservation-availability`,
    payload
  )
const GetOccupiedSeatMapsByProduct = (
  locationCode: string,
  eventCode: string,
  productCode?: string,
  startedAt?: string,
  endedAt?: string
) =>
  axios.post<SeatMapValueObject>(`${CRUD_LOCATION}/${locationCode}/seatmap/availability`, {
    eventCode,
    productCode,
    startedAt,
    endedAt,
  })
const PostSeatMapByLocationCode = (locationCode: string, data: SeatMapModelCreateParams) =>
  axios.post<unknown>(`${CRUD_LOCATION}/${locationCode}/seatmap`, data)
const PutSeatMapByLocationCode = (locationCode: string, data: SeatMapModelCreateParams) =>
  axios.put<unknown>(`${CRUD_LOCATION}/${locationCode}/seatmap`, data)
const DeleteSeatMapByLocationCode = (locationCode: string) =>
  axios.delete<unknown>(`${CRUD_LOCATION}/${locationCode}/seatmap`)

const PostGate = (data: GateModelCreateParams) => axios.post(CRUD_GATE, data)
const GetGateByCode = (gateCode: string) => axios.get(`${CRUD_GATE}/${gateCode}`)
const PutGate = (data: GateModelCreateParams, gateCode: string) =>
  axios.put(`${CRUD_GATE}/${gateCode}`, data)
const PostLocation = (params: LocationModelCreateParams) => {
  return axios.post<LocationModel>(CRUD_LOCATION, getLocationFormData(params))
}
const PutLocation = (params: LocationModelCreateParams, locationCode: string) => {
  return axios.put<LocationModel>(`${CRUD_LOCATION}/${locationCode}`, getLocationFormData(params))
}
const PostVenue = (data: VenueModelCreateParams) => axios.post(CRUD_VENUE, data)

const PutVenue = (data: VenueModelCreateParams, code: string) =>
  axios.put<VenueModel>(`${CRUD_VENUE}/${code}`, data)

const getLocationFormData = (params: LocationModelCreateParams) => {
  const formData = new FormData()
  formData.append('name', params.name)
  formData.append('description', params.description)
  formData.append('venueCode', params.venueCode)
  formData.append('type', params.type)
  params.customFields && formData.append('customFields', JSON.stringify(params.customFields))
  formData.append('isFeatured', String(params.isFeatured))
  params.slug && formData.append('slug', params.slug)
  params.gateCode && formData.append('gateCode', params.gateCode)
  params.entranceCode && formData.append('entranceCode', params.entranceCode)
  params.parentCode && formData.append('parentCode', params.parentCode)
  params.acsFile && formData.append('acsFile', params.acsFile)
  params.geometry && formData.append('geometry', JSON.stringify(params.geometry))
  params.region && formData.append('region', JSON.stringify(params.region))

  params.gateCodes?.forEach((code) => {
    formData.append('gateCodes[]', code)
  })
  params.entranceCodes?.forEach((code) => {
    formData.append('entranceCodes[]', code)
  })
  if (params.translations) {
    Object.entries(params.translations).forEach(([lang, value]) => {
      formData.append(`translations[${lang}]`, JSON.stringify(value))
    })
  }
  params.eventCode && formData.append('eventCode', params.eventCode)
  return formData
}
const GetTimezones = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<TimeZoneModel>>(ACS_SEARCH_TIMEZONES, filter)

const GetTimezoneByCode = (code: string) => axios.get<TimeZoneModel>(`/timezone/${code}`)

const GetIndustries = () => axios.get<IndustryModel[]>(`${CRUD_CUSTOMER}/industries`)

const GetInterests = () => axios.get<IndustryModel[]>(`${CRUD_CUSTOMER}/interests`)

export {
  // DELETE
  DeleteGates,
  DeleteLocations,
  DeleteVenues,
  DeleteSeatMapByLocationCode,
  // SEARCH
  GetParentGates,
  GetParentLocations,
  GetParentLocationByVenue,
  GetParentVenues,
  GetParentVenueTree,
  GetGates,
  GetLocations,
  GetDefaultLocationByCode,
  GetVenues,
  GetVenueByCode,
  GetVenueTranslations,
  GetGateByCode,
  GetLocationByCode,
  GetLocationByEventCode,
  GetEntranceLocationByCode,
  GetGateLocationByCode,
  GetOccupiedSeatMaps,
  GetOccupiedSeatMapsByProduct,
  GetOccupiedSeatMapsByReservation,
  GetGateScanner,
  GetLocationsByProductCode,
  ExportLocations,
  // CREATE
  PostGate,
  PostLocation,
  GetLocationTranslations,
  CreateOrUpdateLocationTranslation,
  PostVenue,
  CreateOrUpdateVenueTranslation,
  PostSeatMapByLocationCode,
  // UPDATE
  PutVenue,
  PutGate,
  PutLocation,
  PutSeatMapByLocationCode,
  //TIMEZONE
  GetTimezones,
  GetTimezoneByCode,
  GetIndustries,
  GetInterests,
}

import clsx from 'clsx'
import {KTSVG} from '../../../_metronic/helpers'
import {DrawerHeader} from './DrawerHeader'
import {DrawerFooter} from './DrawerFooter'
import {DrawerBody} from './DrawerBody'
import {createRef, useCallback, useEffect, useRef} from 'react'

type FormProps = React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>

export interface DrawerFormContainerProps extends FormProps {
  title: string
  isSubmitDisabled?: boolean
  isLoading?: boolean
  submitButtonLabel?: string
  errorMessage?: string
  closeButtonId?: string
  onCloseButtonClick?: () => void
  onSubmitButtonClick?: () => void
  submitButtonType?: 'button' | 'submit' | 'reset'
  classes?: {
    body?: string
  }
  isOpen?: boolean
}

export const DrawerFormContainer = ({
  className,
  children,
  isLoading,
  isSubmitDisabled,
  title,
  errorMessage,
  closeButtonId,
  onCloseButtonClick,
  submitButtonLabel,
  submitButtonType = 'submit',
  onSubmitButtonClick,
  classes,
  isOpen,
  ...formProps
}: DrawerFormContainerProps) => {
  const draw = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (draw.current && isOpen) {
      draw.current.scroll({
        top: 0,
        behavior: 'smooth',
      })
    }
  }, [isOpen, draw])

  return (
    <form
      className={clsx('form-drawer-container drawer-container', className)}
      noValidate
      {...formProps}
    >
      <DrawerHeader className='form-drawer-container__header'>
        <h3 className='fw-bolder text-dark'>{title}</h3>
        <button
          type='button'
          className='btn btn-sm btn-icon btn-active-light-primary'
          id={closeButtonId}
          onClick={onCloseButtonClick}
        >
          <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
        </button>
      </DrawerHeader>
      <DrawerBody otpInputRef={draw} className={classes?.body}>
        {errorMessage && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{errorMessage}</div>
          </div>
        )}
        {children}
      </DrawerBody>
      <DrawerFooter>
        <button
          type={submitButtonType}
          className={
            submitButtonLabel === 'Close'
              ? 'btn btn-lg btn-light-dark w-100'
              : 'btn btn-lg btn-light-primary w-100'
          }
          disabled={isSubmitDisabled}
          onClick={onSubmitButtonClick}
        >
          {!isLoading && <span className='indicator-label'>{submitButtonLabel || 'Saves'}</span>}
          {isLoading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </DrawerFooter>
    </form>
  )
}

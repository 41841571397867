import {string} from 'yup'

const emailValidator = string().email()
export abstract class Validators {
  public static UAE_MOBILE_NUMBER_REGEX =
    /^(?:\+971|971|00971|0|90)(?:50|51|52|53|54|55|56|58)[0-9]{7,10}$/

  public static INTERNATIONAL_MOBILE_NUMBER_REGEX =
    /(\+|00)(297|93|244|1264|358|355|376|971|54|374|1684|1268|61|43|994|257|32|229|226|880|359|973|1242|387|590|375|501|1441|591|55|1246|673|975|267|236|1|61|41|56|86|225|237|243|242|682|57|269|238|506|53|5999|61|1345|357|420|49|253|1767|45|1809|1829|1849|213|593|20|291|212|34|372|251|358|679|500|33|298|691|241|44|995|44|233|350|224|590|220|245|240|30|1473|299|502|594|1671|592|852|504|385|509|36|62|44|91|246|353|98|964|354|972|39|1876|44|962|81|76|77|254|996|855|686|1869|82|383|965|856|961|231|218|1758|423|94|266|370|352|371|853|590|212|377|373|261|960|52|692|389|223|356|95|382|976|1670|258|222|1664|596|230|265|60|262|264|687|227|672|234|505|683|31|47|977|674|64|968|92|507|64|51|63|680|675|48|1787|1939|850|351|595|970|689|974|262|40|7|250|966|249|221|65|500|4779|677|232|503|378|252|508|381|211|239|597|421|386|46|268|1721|248|963|1649|235|228|66|992|690|993|670|676|1868|216|90|688|886|255|256|380|598|1|998|3906698|379|1784|58|1284|1340|84|678|681|685|967|27|260|263)(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{4,20}$/

  public static DUTCH_MOBILE_NUMBER_REGEX =
    /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/
  public static REF_CODES_REGEX = /^[0-9A-Za-z,.:|\s]*$/
  //eslint-disable-next-line
  public static NO_SPECIAL_CHAR_REGEX = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/

  /**
   * Fractional numbers
   * This includes numbers like 0, 1, 0.0, 0.1, 1.0, 99999.000001, 5.10, -0, -1, -0.0, -0.1, -99999.000001, 5.10.
   * */
  public static FRACTIONAL_NUMBER_REGEX = /^-?(0|[1-9]\d*)(\.\d+)?$/

  public static WHOLE_NUMBER_REGEX = /^[0-9]*$/

  public static isEmail = (value: unknown) => {
    try {
      emailValidator.validateSync(value)
      return true
    } catch (e) {
      return false
    }
  }

  public static isUrl = (string: string) => {
    try {
      const url = new URL(string)
      return url.protocol === 'http:' || url.protocol === 'https:'
    } catch (e) {
      return false
    }
  }
}

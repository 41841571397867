import {FormikContextType} from 'formik'
import {
  RichTextEditor,
  RichTextEditorValue,
} from '../../../../../components/inputs/RichTextEditor/RichTextEditor'
import {useCallback, useRef} from 'react'

export interface RegistrationCustomerBioValues {
  bio: RichTextEditorValue | null
  //   bio: string
}

export interface RegistrationCustomerBioProps<T extends RegistrationCustomerBioValues> {
  formik: FormikContextType<T>
}

export const RegistrationCustomerBio = <T extends RegistrationCustomerBioValues>({
  formik,
}: RegistrationCustomerBioProps<T>) => {
  const editorRef = useRef<RichTextEditor | null>(null)
  //   const {uploadFiles, deleteFiles} = useEventWizardUtils()
  //   const {
  //     disableState: closeFileGrid,
  //     enableState: openFileGrid,
  //     state: isFileGridOpen,
  //   } = useBooleanState(false)

  const handleDescriptionChange = useCallback(
    (value: RichTextEditorValue) => {
      formik.setFieldValue('bio', value)
    },
    [formik]
  )
  return (
    <div className='row reg-bio'>
      <div className='col-sm-12 col-12 mb-5'>
        <RichTextEditor
          classes={{textArea: 'min-h-200px mb-5'}}
          ref={editorRef}
          onChange={handleDescriptionChange}
          value={formik.values.bio}
          hideControls={true}
          //    onUpload={openFileGrid}
        />
      </div>
    </div>
  )
}

export const STEP_BIO_KEYS: Array<keyof RegistrationCustomerBioValues> = ['bio']

import {FormikContextType} from 'formik'
import {TextInput} from '../../../../../components/inputs'
import {useFormikStringInputHelpers} from '../../../../../components/inputs/hooks/useFormikStringInputHelpers'
import {useFormikTextInputHelpers} from '../../../../../components/inputs/hooks/useFormikTextInputHelper'
import {MobileNumberInput} from '../../../../../components/inputs/MobileNumberInput'

export interface RegistrationPersonalAssistantValues {
  personalAssistantName: string
  personalAssistantLastName: string
  personalAssistantEmail: string
  personalAssistantMobileNumber: string
  // personalAssistantPhoto: ImageInputValue | null
}

export interface RegistrationPersonalAssistantProps<T extends RegistrationPersonalAssistantValues> {
  formik: FormikContextType<T>
}

export const RegistrationPersonalAssistant = <T extends RegistrationPersonalAssistantValues>({
  formik,
}: RegistrationPersonalAssistantProps<T>) => {
  // const avatarInputRef = useRef<HTMLInputElement>(null)
  // const imageInputHelpers = useFormikImageInputHelpers(formik)
  const stringInputHelpers = useFormikStringInputHelpers(formik)
  const textInputHelpers = useFormikTextInputHelpers(formik)

  // const handleAvatarInputChange = useCallback(
  //   (image: ImageInputValue | null) => {
  //     formik.setFieldValue('personalAssistantPhoto', image)
  //   },
  //   [formik]
  // )

  // const handleResetPhotoClick = useCallback(() => {
  //   formik.setFieldValue('personalAssistantPhoto', null)
  //   formik.setFieldValue('personalAssistantPhotoCropped', null)
  // }, [formik])

  // const handleUploadPhotoClick = useCallback(() => {
  //   avatarInputRef.current?.click()
  // }, [])

  return (
    <div className='row'>
      {/* <div className='col-12'>
        <AvatarImageInput
          inputRef={avatarInputRef}
          height={500}
          width={500}
          className='mb-3'
          label='Upload personal assistant photo'
          {...imageInputHelpers.getProps('personalAssistantPhoto')}
          onChange={handleAvatarInputChange}
        />
        <div className='d-flex justify-content-end'>
          <Button type='button' className='me-1' variant='danger' onClick={handleResetPhotoClick}>
            Clear
          </Button>
          <Button type='button' variant='primary' onClick={handleUploadPhotoClick}>
            Upload
          </Button>
        </div>
      </div> */}
      <div className='col-sm-6 col-12'>
        <TextInput label='First Name' {...textInputHelpers.getProps('personalAssistantName')} />
      </div>
      <div className='col-sm-6 col-12'>
        <TextInput label='Last Name' {...textInputHelpers.getProps('personalAssistantLastName')} />
      </div>
      <div className='col-sm-6 col-12'>
        <TextInput
          label='Email'
          type='email'
          {...textInputHelpers.getProps('personalAssistantEmail')}
        />
      </div>
      <div className='col-sm-6 col-12'>
        <MobileNumberInput
          label='Mobile Phone'
          //   helperMessage='Ex: +971551234567'
          {...stringInputHelpers.getProps('personalAssistantMobileNumber')}
        />
      </div>
    </div>
  )
}

export const STEP_ASSISTANT_KEYS: Array<keyof RegistrationPersonalAssistantValues> = [
  'personalAssistantEmail',
  'personalAssistantMobileNumber',
  'personalAssistantName',
  // 'personalAssistantPhoto',
]

import {FormikContextType} from 'formik'
import {useCallback, useRef} from 'react'
import {DatePickerInput, TextInput} from '../../../../../components/inputs'
import {AvatarImageInput} from '../../../../../components/inputs/AvatarImageInput/AvatarImageInput'
import {Button} from '../../../../../components/inputs/Button'
import {BaseFileInputValue} from '../../../../../components/inputs/FileInput/BaseFileInputValue'
import {DragDropFileInput} from '../../../../../components/inputs/FileInput/DragDropFIleInput/DragDropFileInput'
import {FileInputValueCollection} from '../../../../../components/inputs/FileInput/FileInputValueCollection'
import {ImageInputValue} from '../../../../../components/inputs/FileInput/ImageInputValue'
import {useFormikDatePickerInputHelpers} from '../../../../../components/inputs/hooks/useFormikDatePickerInputHelpers'
import {useFormikFileInputHelpers} from '../../../../../components/inputs/hooks/useFormikFileInputHelpers'
import {useFormikImageInputHelpers} from '../../../../../components/inputs/hooks/useFormikImageInputHelper'
import {useFormikTextInputHelpers} from '../../../../../components/inputs/hooks/useFormikTextInputHelper'
import {CustomFieldCreateValue} from '../../../../../models/system/CustomFieldModel'
import {EventModel} from '../../../../../models/ems/EventModel'
import {DateUtil} from '../../../../../utils/DateUtil'

export interface RegistrationPersonalInformationProps<
  T extends RegistrationPersonalInformationValues
> {
  formik: FormikContextType<T>
}

export interface RegistrationPersonalInformationValues {
  dateOfBirth: Date | null
  passportPhoto: ImageInputValue | null
  passportPhotoCropped: ImageInputValue | null
  passportCopy: FileInputValueCollection<BaseFileInputValue>
  documentExpiry: Date | null
  isFlight: boolean
  isHotel: boolean
  isTransportation: boolean
  flightDetailOne: string
  flightDetailTwo: string
  flightCheckinDate: Date | null
  flightCheckoutDate: Date | null
  hotelCheckinDate: Date | null
  hotelCheckoutDate: Date | null
  transportationDetailOne: string
  transportationCheckinDate: Date | null
  formType: string
  vip: string
  classType: string
  category: string
  passportNumber: string
  reason: string
  accessType: string
  guestCustomFieldValues: CustomFieldCreateValue[]
  event?: EventModel | null
  issuedDate?: Date | null
}

export const RegistrationPersonalInformation = <T extends RegistrationPersonalInformationValues>({
  formik,
}: RegistrationPersonalInformationProps<T>) => {
  const avatarInputRef = useRef<HTMLInputElement>(null)
  const passportInputRef = useRef<HTMLInputElement>(null)
  const dateInputHelpers = useFormikDatePickerInputHelpers(formik)
  // const stringInputHelpers = useFormikStringInputHelpers(formik)
  const imageInputHelpers = useFormikImageInputHelpers(formik)
  const fileInputHelpers = useFormikFileInputHelpers(formik)
  const textInputHelpers = useFormikTextInputHelpers(formik)

  const handleAvatarInputChange = useCallback(
    (image: ImageInputValue | null) => {
      formik.setFieldValue('passportPhoto', image)
    },
    [formik]
  )

  // const handleNationalityChange = useCallback(
  //   (key: string) => (value: ISOCountryModel | null) => {
  //     formik.setFieldValue(key, value)
  //   },
  //   [formik]
  // )

  // const handleNationalityBlur = useCallback(
  //   (key: string) => () => {
  //     formik.setFieldTouched(key)
  //   },
  //   [formik]
  // )

  const handleUploadPhotoClick = useCallback(() => {
    avatarInputRef.current?.click()
  }, [])

  const handleResetPhotoClick = useCallback(() => {
    formik.setFieldValue('passportPhoto', null)
    formik.setFieldValue('passportPhotoCropped', null)
  }, [formik])

  const handleUploadPassportClick = useCallback(() => {
    passportInputRef.current?.click()
  }, [])

  const handleResetPassportClick = useCallback(() => {
    formik.setFieldValue('passportCopy', new FileInputValueCollection())
  }, [formik])

  // const isFlight = useFormikCheckboxInputHelpers(formik, 'isFlight')
  // const isHotel = useFormikCheckboxInputHelpers(formik, 'isHotel')
  // const isTransportation = useFormikCheckboxInputHelpers(formik, 'isTransportation')

  // const handleDateChange = useCallback(
  //   (fieldName: keyof T) => (date: Date | null) => {
  //     formik.setFieldValue(fieldName as string, date)
  //   },
  //   [formik]
  // )
  return (
    <div className='row'>
      {/* <div className='col-12'>
        <DatePickerInput
          label='Date of birth'
          maxDate={TODAY}
          {...dateInputHelpers.getProps('dateOfBirth')}
        />
      </div> */}
      {formik.values.formType.toLocaleUpperCase() === 'SPEAKER' && (
        <>
          <div className='col-12'>
            <AvatarImageInput
              isRequired
              inputRef={avatarInputRef}
              height={500}
              width={500}
              className='mb-3'
              label='Photo'
              {...imageInputHelpers.getProps('passportPhoto')}
              onChange={handleAvatarInputChange}
            />
            <div className='d-flex justify-content-end'>
              <Button
                type='button'
                className='me-1'
                variant='danger'
                onClick={handleResetPhotoClick}
              >
                Clear
              </Button>
              <Button type='button' variant='primary' onClick={handleUploadPhotoClick}>
                Upload
              </Button>
            </div>
          </div>
          <div className='col-12'>
            <DragDropFileInput
              isRequired
              limit={0}
              label={'Passport Copy (or Emirates ID Copy if UAE resident)'}
              inputRef={passportInputRef}
              {...fileInputHelpers.getProps('passportCopy')}
            />
            <div className='d-flex justify-content-end'>
              <Button
                type='button'
                className='me-1'
                variant='danger'
                onClick={handleResetPassportClick}
              >
                Clear
              </Button>
              <Button type='button' variant='primary' onClick={handleUploadPassportClick}>
                Upload
              </Button>
            </div>
          </div>
          <div className='col-sm-12 col-12 mt-3'>
            <TextInput
              isRequired
              label='Passport No. (or Emirates ID number if UAE resident)'
              {...textInputHelpers.getProps('passportNumber')}
            />
          </div>
          <div className='col-sm-6 col-12'>
            <DatePickerInput
              isRequired
              label={'Issue Date'}
              {...dateInputHelpers.getProps('issuedDate')}
              maxDate={new Date()}
            />
          </div>
          <div className='col-sm-6 col-12'>
            <DatePickerInput
              isRequired
              label={'Expiry Date'}
              {...dateInputHelpers.getProps('documentExpiry')}
              minDate={
                formik.values.event?.endedAt
                  ? DateUtil.getDateFromApiString(formik.values.event?.endedAt)
                  : new Date()
              }
            />
          </div>
        </>
      )}
      {/* <div className='col-12'>
        <label className='form-label'>Need assistance?</label>
      </div>
      <div className='col-12'>
        <CheckboxInput label='Flights' onChange={isFlight.onChange} checked={isFlight.value} />
      </div>
      {isFlight.value === true && (
        <>
          <div className='col-12'>
            <TextInput
              label='Preferred Airlines'
              placeholder='Enter Preferred Airlines'
              {...textInputHelpers.getProps('flightDetailOne')}
            />
          </div>
          <div className='col-12'>
            <TextInput
              label='Departure City'
              placeholder='Enter Departure City'
              {...textInputHelpers.getProps('flightDetailTwo')}
            />
          </div>
          <div className='col-12'>
            <DateTimePickerInput
              label='Departure Date & Time'
              value={formik.values.flightCheckinDate}
              onChange={handleDateChange('flightCheckinDate')}
              minDate={TODAY}
              noMargin
            />
          </div>
          <div className='col-12 my-5'>
            <DateTimePickerInput
              label='Return Date & Time'
              value={formik.values.flightCheckoutDate}
              onChange={handleDateChange('flightCheckoutDate')}
              minDate={TODAY}
              noMargin
            />
          </div>
        </>
      )}
      <div className='col-12'>
        <CheckboxInput
          label='Hotel Reservation'
          onChange={isHotel.onChange}
          checked={isHotel.value}
        />
      </div>
      {isHotel.value === true && (
        <>
          <div className='col-12'>
            <DateTimePickerInput
              label='Check-in Date'
              value={formik.values.hotelCheckinDate}
              onChange={handleDateChange('hotelCheckinDate')}
              minDate={TODAY}
              noMargin
            />
          </div>
          <div className='col-12 my-5'>
            <DateTimePickerInput
              label='Check-out Date'
              value={formik.values.hotelCheckoutDate}
              onChange={handleDateChange('hotelCheckoutDate')}
              minDate={TODAY}
              noMargin
            />
          </div>
        </>
      )}

      <div className='col-12'>
        <CheckboxInput
          label='Transportation'
          onChange={isTransportation.onChange}
          checked={isTransportation.value}
        />
      </div>
      {isTransportation.value === true && (
        <>
          <div className='col-12'>
            <TextInput
              label='Trip Details'
              placeholder='E.g. Airport Transfer..'
              {...textInputHelpers.getProps('transportationDetailOne')}
            />
          </div>
          <div className='col-12 mb-10'>
            <DateTimePickerInput
              label='Date & Time'
              value={formik.values.transportationCheckinDate}
              onChange={handleDateChange('transportationCheckinDate')}
              minDate={TODAY}
              noMargin
            />
          </div>
        </>
      )} */}
    </div>
  )
}

export const STEP_INFORMATION_KEYS: Array<keyof RegistrationPersonalInformationValues> = [
  'dateOfBirth',
  // 'nationality',
  'passportCopy',
  'documentExpiry',
  'passportPhoto',
  'issuedDate',
  'passportNumber',
  'flightDetailTwo',
  'flightCheckinDate',
  'flightCheckoutDate',
  'hotelCheckinDate',
  'hotelCheckoutDate',
  'transportationDetailOne',
  'transportationCheckinDate',
]

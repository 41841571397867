import clsx from 'clsx'
import {ReactNode, useMemo, useRef} from 'react'
import DatePicker from 'react-date-picker'
import {v4 as uuidv4} from 'uuid'

export interface DatePickerInputProps {
  className?: string
  value: Date | null
  onChange: (value: Date | null) => void
  minDate?: Date
  maxDate?: Date
  label?: string | ReactNode
  disabled?: boolean
  noMargin?: boolean
  noClear?: boolean
  format?: string
  isRequired?: boolean
}

export const DatePickerInput = ({
  className,
  value,
  onChange,
  minDate,
  maxDate,
  label,
  disabled,
  noMargin,
  noClear,
  format,
  isRequired,
}: DatePickerInputProps) => {
  const id = useRef(uuidv4()).current
  const locale = useMemo(() => {
    if (navigator?.language) {
      return navigator.language
    }
    return 'en-US'
  }, [])

  return (
    <div className={clsx({'mb-5': !noMargin}, className)}>
      {/* {label && <label className='form-label'>{label}</label>} */}
      {label && (
        <label className={isRequired ? 'form-label required' : 'form-label'} htmlFor={id}>
          {label}
        </label>
      )}
      <div>
        <DatePicker
          disabled={disabled}
          locale={locale}
          className='form-date-input form-control-solid'
          value={value ? new Date(value) : null}
          minDate={minDate ? new Date(minDate) : undefined}
          onChange={(date: any) => onChange(date ? new Date(date) : null)}
          maxDate={maxDate ? new Date(maxDate) : undefined}
          clearIcon={noClear ? null : undefined}
          format={format}
        />
      </div>
    </div>
  )
}

import {ReactNode} from 'react'
import clsx from 'clsx'

type DivProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export interface DrawerBodyProps extends DivProps {
  children: ReactNode
  otpInputRef?: React.MutableRefObject<HTMLDivElement | null>
}

export const DrawerBody = ({children, otpInputRef, className, ...otherProps}: DrawerBodyProps) => {
  return (
    <div ref={otpInputRef} className={clsx('drawer-body', className)} {...otherProps}>
      {children}
    </div>
  )
}

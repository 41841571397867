import clsx from 'clsx'
import {ReactNode, useCallback, useEffect, useMemo, useState} from 'react'
import {DateRange} from '../../../../utils/DateRange'
import {useAlerts} from '../../../alerts/useAlerts'
import {DateTimePickerInput} from '../../../inputs/datepickers/DateTimePickerInput'
import {HorizontalDivider} from '../../../utils/HorizontalDivider'
import {SweetAlert} from '../../../modals/SweetAlert'
import {Button} from '../../../inputs/Button'
import {MetronicIconButton} from '../../../inputs/MetronicIconButton'
import {NonSeatedProductTimeSlot} from '../../../../models/ems/BookingModel'
import {DateUtil} from '../../../../utils/DateUtil'

export interface NonSeatedTimeslotDateModalFormProps {
  onSubmit: (dates: DateRange[]) => void
  minDate?: Date
  maxDate?: Date
  qty?: number
  open: boolean
  onHide: () => void
  initialDates?: NonSeatedProductTimeSlot[]
}

export const NonSeatedTimeslotDateModalForm = ({
  onHide,
  open,
  onSubmit,
  maxDate,
  minDate,
  qty = 0,
  initialDates,
}: NonSeatedTimeslotDateModalFormProps) => {
  const {push} = useAlerts()
  const [dates, setDates] = useState<DateRange[]>([])

  const handleChangeStart = useCallback(
    (index: number, startOrEnd: 0 | 1) => (date: Date | null) => {
      const updateDateRangeValue = (dateRange: DateRange) => {
        if (startOrEnd === 0) {
          dateRange.setStart(date)
          if (!dateRange.getEnd()) {
            dateRange.setEnd(date)
          }
        } else {
          dateRange.setEnd(date)
        }
      }

      setDates((dates) => {
        const newDates = [...dates]
        const dateRange = newDates[index]
        if (dateRange) {
          updateDateRangeValue(dateRange)
        } else {
          const dateRange = new DateRange()
          newDates[index] = dateRange
          updateDateRangeValue(dateRange)
        }

        return newDates
      })
    },
    []
  )

  const inputs = useMemo(() => {
    const nodes: ReactNode[] = []

    for (let i = 0; i < qty; i++) {
      nodes.push(
        <div key={i} className='d-flex flex-column gap-3'>
          <div className='fw-bold mb-2'>{`Ticket ${i + 1}`}</div>
          <DateTimePickerInput
            label='Start'
            minDate={minDate}
            maxDate={maxDate}
            onChange={handleChangeStart(i, 0)}
            value={dates[i]?.getStart() || null}
            noClear
            noMargin
          />
          <DateTimePickerInput
            label='End'
            minDate={minDate}
            maxDate={maxDate}
            onChange={handleChangeStart(i, 1)}
            value={dates[i]?.getEnd() || null}
            noClear
          />
          {i !== qty - 1 && <HorizontalDivider />}
        </div>
      )
    }

    return nodes
  }, [dates, handleChangeStart, maxDate, minDate, qty])

  const isValid = useMemo(() => {
    for (let i = 0; i < qty; i++) {
      if (!dates[i]) {
        return false
      }
      if (!dates[i].hasValues()) {
        return false
      }
    }
    return dates.length === qty
  }, [dates, qty])

  useEffect(() => {
    if (initialDates && initialDates.length) {
      for (let i = 0; i < initialDates.length; i++) {
        setDates((dates) => {
          const newDates = [...dates]
          const dateRange = new DateRange(
            DateUtil.getDateFromApiString(initialDates[i].startedAt),
            DateUtil.getDateFromApiString(initialDates[i].endedAt)
          )
          newDates[i] = dateRange
          return newDates
        })
      }
    } else {
      setDates([])
    }
  }, [open, initialDates])

  // useOnChange(initialDates, () => {
  //   if (initialDates && initialDates.length) {
  //     for (let i = 0; i < initialDates.length; i++) {
  //       setDates((dates) => {
  //         const newDates = [...dates]
  //         const dateRange = new DateRange(
  //           DateUtil.getDateFromApiString(initialDates[i].startedAt),
  //           DateUtil.getDateFromApiString(initialDates[i].endedAt)
  //         )
  //         newDates[i] = dateRange
  //         return newDates
  //       })
  //     }
  //   }
  // })

  const handleSubmit = useCallback(() => {
    let errorIndex = dates.findIndex((date) => {
      const isValid =
        date.hasValues() && date.getStartOrFail().valueOf() !== date.getEndOrFail().valueOf()
      return !isValid
    })

    if (errorIndex >= 0) {
      push({
        timeout: 5000,
        message: `Invalid date on ticket ${errorIndex + 1}`,
        variant: 'danger',
      })
    } else {
      onSubmit(dates as DateRange[])
    }
  }, [dates, onSubmit, push])

  return (
    <SweetAlert
      customClass={{htmlContainer: 'overflow-visible'}}
      onClose={onHide}
      open={open}
      showConfirmButton={false}
    >
      <div className='swal2-corners'></div>
      <div className='position-relative p-3'>
        <div className='text-start'>
          <div className='d-flex justify-content-between'>
            <div className='fs-3 fw-bold mb-3'>Select Dates</div>
            <MetronicIconButton
              variant='text'
              size='md'
              iconType='Navigation'
              iconName='Close'
              tooltip='Close Modal'
              onClick={onHide}
            />
          </div>

          <div>{inputs}</div>
          <div className='d-flex justify-content-center'>
            <Button
              className={clsx(
                'btn btn-primary'
                // isPortal && 'position-relative btn-cut-conrner pe-20'
              )}
              disabled={!isValid}
              onClick={handleSubmit}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </SweetAlert>
  )
}

import 'react-phone-number-input/style.css'
import {ReactNode, useCallback, useMemo} from 'react'
// import {InputLabel} from './InputLabel'
import PhoneInput from 'react-phone-number-input'
import clsx from 'clsx'

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

export interface MobileNumberInputProps extends Omit<InputProps, 'value' | 'onChange' | 'ref'> {
  value: string
  onChange: (value: string) => void
  inputClassName?: string
  label?: string | ReactNode
  errorMessage?: string
  helperMessage?: string
  isTouched?: boolean
  noMargin?: boolean
  isRequired?: boolean
}

export const MobileNumberInput = ({
  onChange,
  value,
  id,
  label,
  errorMessage,
  helperMessage,
  isTouched,
  isRequired,
  className,
  onFocus,
  onBlur,
  inputClassName,
  noMargin,
  ...inputProps
}: MobileNumberInputProps) => {
  const handleChange = useCallback(
    (phoneNumber: string = '') => {
      onChange(phoneNumber)
    },
    [onChange]
  )

  const validationClassName = useMemo(() => {
    if (!isTouched) {
      return null
    }
    return errorMessage ? 'is-invalid' : 'is-valid'
  }, [errorMessage, isTouched])

  const footer = useMemo(() => {
    return (
      <div className='fv-plugins-message-container'>
        <div className='fv-help-block'>
          {isTouched && errorMessage && <span role='alert'>{errorMessage}</span>}
        </div>
        <div className='fv-help-block'>
          {helperMessage && <span role='alert'>{helperMessage}</span>}
        </div>
      </div>
    )
  }, [errorMessage, helperMessage, isTouched])

  return (
    <div className={clsx({'mb-5': !noMargin}, className)}>
      {/* {label && <InputLabel htmlFor={id}>{label}</InputLabel>} */}
      {label && (
        <label
          className={isRequired ? 'form-label w-100 required' : 'form-label w-100'}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      <PhoneInput
        smartCaret={false}
        numberInputProps={{
          ...inputProps,
          className: clsx('form-control form-control-solid', validationClassName, inputClassName),
        }}
        onBlur={onBlur}
        onFocus={onFocus}
        value={value}
        onChange={handleChange}
      />
      {footer}
    </div>
  )
}

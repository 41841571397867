import {
  BookingModelCreateParams,
  PortalBookingModelCreateParams,
} from '../../../models/customer-portal/BookingModel'
import {
  ReservationCreateParams,
  ReservationPortalModel,
} from './../../../models/ems/ReservationMedel'
import {BookingModel, BulkBookingModelCreateParams} from './../../../models/ems/BookingModel'
import axios from 'axios'
import {SeatMapValueObject} from '../../../components/inputs/SeatMapInput/SeatMapValue'
import {LocationModel} from '../../../models/acs/LocationModel'
import {AuthModel} from '../../../models/customer-portal/AuthModel'
import {CustomerGroupModel} from '../../../models/customer-portal/CustomerModel'
import {PortalSearchModel} from '../../../models/customer-portal/PortalSearchModel'
import {
  CustomerModel,
  CustomerModelCreateFormData,
  CustomerWithParentModel,
} from '../../../models/CustomerModel'
import {EventModel} from '../../../models/ems/EventModel'
import {ProductModel} from '../../../models/ems/ProductModel'
import {FilterModel} from '../../../models/FilterModel'
import {ISOCountryModel} from '../../../models/ISOCountryModel'
import {
  TicketPortalModel,
  TicketModelChangeSeatParams,
  TicketModelFulfillParams,
  TicketModel,
} from '../../../models/ems/TicketModel'
import {GlobalSearchModel} from '../../../models/GlobalSearchModel'
import {FileInputValue} from '../../../components/inputs/FileInput/FileInputValue'
import {VoucherModel} from '../../../models/svc/VoucherModel'
import {BookingDetailModel} from '../../../models/customer-portal/BookingDetailModel'
import {WidgetModel} from '../../../models/customer-portal/WidgetModel'
import {PortalWidgetModal} from '../../../models/customer-portal/PortalWidgetModal'
import {ReservationDetailsModel} from '../../../models/customer-portal/ReservationDetalsModel'
import {
  ReservationItemModel,
  ReservationModelSeatedAndNonSeatedCreateParams,
} from '../../../models/ems/ReservationModel'
import {CreateCustomerParams} from '../../customer-booking/components/booking-wizard/steps/BookingWizardRegistrationInformation'
import {UserModel, UserModelCreateFormData} from '../../../models/customer-portal/UserModel'
import {UserLogModel} from '../../../models/UserLogModel'

const PORTAL_AUHT_BASE_PATH = '/portal-auth'
const VOUCHERS_BASE_PATH = '/vouchers'
const TICKETS_BASE_PATH = '/tickets'
const CUSTOMER_BASE_PATH = '/customer'
const LOCATION_BASE_PATH = '/location'
const BOOKING_BASE_PATH = '/bookings'
const EVENT_BASE_PATH = '/event'
const PRODUCT_BASE_PATH = '/product'
const BOOKING_PRODUCT_BASE_PATH = '/bookings/product'
const RESERVATION_BASE_PATH = '/reservation'
const USER_BASE_PATH = '/user'

export interface EmailTypeParams {
  email: string
  type: string
}

export interface CustomerCodeParams {
  customerCode: string
}

interface ReassignSeatParams {
  reason: string
  locationCode: string
  seatRow: string
  seatNo: number
}

// LOGIN
export const AuthLogin = (username: string, password: string, accountCode?: string) =>
  axios.post<AuthModel>(`${PORTAL_AUHT_BASE_PATH}/login`, {username, password, accountCode})
export const RequestForgottenPassword = (email: string, accountCode?: string) =>
  axios.post<AuthModel>(`${PORTAL_AUHT_BASE_PATH}/forgot-password`, {email, accountCode})
export const SignUpCustomer = (data: CreateCustomerParams) =>
  axios.post<AuthModel>(`${PORTAL_AUHT_BASE_PATH}/sign-up`, data)
export const VerifyCustomerAuthToken = () =>
  axios.get<AuthModel>(`${PORTAL_AUHT_BASE_PATH}/verify-token`)
export const ResetPassword = (password: string) =>
  axios.post(`${PORTAL_AUHT_BASE_PATH}/reset`, {password})
export const ResetSignUpPassword = (password: string) =>
  axios.post(`${PORTAL_AUHT_BASE_PATH}/reset-customer`, {password})
export const validateHash = (hash: string) =>
  axios.post(`${PORTAL_AUHT_BASE_PATH}/validate-hash`, {hash})

export const validateMfa = (mfa: string, authToken: string) =>
  axios.post(
    `${PORTAL_AUHT_BASE_PATH}/validate-mfa`,
    {mfa},
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  )

export const loginWithHash = (
  username: string,
  password: string,
  hash: string,
  accountCode?: string
) => axios.post(`${PORTAL_AUHT_BASE_PATH}/login-hash`, {username, password, hash, accountCode})

// CUSTOMERS
export const GetCustomerList = (filter?: FilterModel) =>
  axios.post<PortalSearchModel<CustomerGroupModel>>(`${CUSTOMER_BASE_PATH}/list`, filter)

export const GetCustomerListFlatten = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<CustomerModel> | PortalSearchModel<CustomerModel>>(
    `${CUSTOMER_BASE_PATH}/list`,
    filter
  )

export const GetCustomerByEmailAndType = (payload: EmailTypeParams) =>
  axios.post<CustomerModel>(`${CUSTOMER_BASE_PATH}/email`, payload)

export const AddExistedCustomer = (payload: CustomerCodeParams) =>
  axios.post(`${CUSTOMER_BASE_PATH}/add`, payload)

export const PostCustomerFiles = async (data: FileInputValue[], customerCode: string) => {
  const formData = new FormData()
  data.forEach((file) => {
    const document = file.getFile()
    if (document) {
      formData.append('documents', document)
    }
  })
  await axios.put(`${CUSTOMER_BASE_PATH}/documents/upload/${customerCode}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const DeleteCustomerFiles = async (fileCodes: string[], customerCode: string) => {
  await axios.delete(`${CUSTOMER_BASE_PATH}/documents/${customerCode}`, {data: {codes: fileCodes}})
}

export const DeleteCustomer = (customerCode: string[] | string) =>
  axios.delete(`${CUSTOMER_BASE_PATH}/`, {
    data: {codes: Array.isArray(customerCode) ? customerCode : [customerCode]},
  })
export const GetCustomerByCode = (code: string) =>
  axios.get<CustomerWithParentModel>(`${CUSTOMER_BASE_PATH}/${code}`)

export const PostCustomer = (data: CustomerModelCreateFormData) =>
  axios.post(`${CUSTOMER_BASE_PATH}`, data.getFormData(), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

export const PutCustomer = (data: CustomerModelCreateFormData, customerCode: string) =>
  axios.put(`${CUSTOMER_BASE_PATH}/${customerCode}`, data.getFormData(), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

// USERS
export const GetUserByCode = (code: string) =>
  axios.get<UserModel>(`${CUSTOMER_BASE_PATH}${USER_BASE_PATH}/${code}`)

export const PostUser = (data: UserModelCreateFormData) =>
  axios.post(`${CUSTOMER_BASE_PATH}${USER_BASE_PATH}`, data.getFormData(), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

export const PutUser = (data: CustomerModelCreateFormData, userCode: string) =>
  axios.put(`${CUSTOMER_BASE_PATH}${USER_BASE_PATH}/${userCode}`, data.getFormData(), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

export const DeleteUser = (userCode: string[] | string) =>
  axios.delete(`${CUSTOMER_BASE_PATH}${USER_BASE_PATH}`, {
    data: {codes: Array.isArray(userCode) ? userCode : [userCode]},
  })

export const GetUserList = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<UserModel> | PortalSearchModel<UserModel>>(
    `${CUSTOMER_BASE_PATH}${USER_BASE_PATH}/list`,
    filter
  )

export const ActivateUsers = (userCodes: string[]) =>
  axios.post(`${CUSTOMER_BASE_PATH}${USER_BASE_PATH}/send-credentials`, {codes: userCodes})

export const ResetUserMFA = (userCodes: string[]) =>
  axios.post(`${CUSTOMER_BASE_PATH}${USER_BASE_PATH}/reset-mfa`, {
    codes: userCodes,
  })

export const PostUserSmartPin = (smartPin: string, codes: string[]) =>
  axios.post(`${CUSTOMER_BASE_PATH}${USER_BASE_PATH}/smart-pin`, {
    smartPin,
    codes,
  })

// LOGS
export const GetUserLogs = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<UserLogModel>>(`logs/list`, filter)

// TICKETS

export const GetTicketList = (filter?: FilterModel) =>
  axios.post(`${TICKETS_BASE_PATH}/list`, filter)
export const ChangeTicketSeat = (ticketCode: string, payload: TicketModelChangeSeatParams) =>
  axios.put<Blob>(`${TICKETS_BASE_PATH}/${ticketCode}/change-seat`, payload, {
    responseType: 'blob',
  })

export const GetTicketByCode = (code: string) =>
  axios.get<TicketModel>(`${TICKETS_BASE_PATH}/${code}`)

export const CancelTicket = (ticketCode: string[], reason: string) =>
  axios.post<unknown>(`${TICKETS_BASE_PATH}/cancel`, {reason, codes: ticketCode})

export const DownloadTicket = (ticketCode: string[]) =>
  axios.post<Blob>(
    `${TICKETS_BASE_PATH}/download`,
    {codes: ticketCode},
    {
      responseType: 'blob',
    }
  )

export const FulfillTicket = (data: TicketModelFulfillParams) =>
  axios.post<GlobalSearchModel<TicketPortalModel>>(`${TICKETS_BASE_PATH}/fulfill`, data)

export const FulfillNonSeated = (bookingProductCode: string) =>
  axios.post<GlobalSearchModel<TicketPortalModel>>(`${TICKETS_BASE_PATH}/fulfill/non-seated`, {
    productCode: bookingProductCode,
  })

export const ShareSeatMap = (
  productCode: string,
  sharingCustomerCode: string,
  customerShareTo: string,
  seats: SeatMapValueObject,
  type: string
) =>
  axios.post(`${TICKETS_BASE_PATH}/share`, {
    seats,
    productCode,
    sharingCustomerCode,
    customerCode: customerShareTo,
    type,
  })

export interface ShareEmailTicketProps {
  ticketCodes: string[]
  eventCode: string
  name?: string
  email?: string
}
interface ShareTicketModel {
  links: [{link: string; name: string}]
}

export const ShareTicketViaEmail = (payload: ShareEmailTicketProps) =>
  axios.post<ShareTicketModel>(`${TICKETS_BASE_PATH}/share/email`, payload)

export const PutReassignSeat = (payload: ReassignSeatParams, ticketCode: string) =>
  axios.put(`${TICKETS_BASE_PATH}/${ticketCode}`, payload)

// VOUCHER

export const GetVoucherList = (filter?: FilterModel) =>
  axios.post(`${VOUCHERS_BASE_PATH}/list`, filter)

export const SearchVouchers = (filter?: FilterModel) =>
  axios.post(`${VOUCHERS_BASE_PATH}/list`, filter)

export const CancelVoucher = (voucherCode: string[], reason: string) =>
  axios.post<unknown>(`${VOUCHERS_BASE_PATH}/cancel`, {reason, codes: voucherCode})
export const DisableVoucher = (voucherCode: string[]) =>
  axios.post<unknown>(`${VOUCHERS_BASE_PATH}/disable`, {codes: voucherCode})

export const FulfillNonSeatedVoucher = (bookingProductCode: string) =>
  axios.post<GlobalSearchModel<TicketPortalModel>>(`${VOUCHERS_BASE_PATH}/fulfill`, {
    bookingProductCode,
  })

export const DownloadVouchers = (voucherCode: string[]) =>
  axios.post<Blob>(
    `${VOUCHERS_BASE_PATH}/download`,
    {codes: voucherCode},
    {
      responseType: 'blob',
    }
  )

export const GetAvailableSeatMaps = (
  locationCode: string,
  productCode: string,
  eventCode: string,
  customerCode: string,
  startedAt?: string,
  endedAt?: string
) =>
  axios.post(`/location/${locationCode}/availability`, {
    productCode,
    eventCode,
    customerCode,
    startedAt,
    endedAt,
  })

export const GetSeatsByLocationCode = (locationCode: string) =>
  axios.get<LocationModel>(`${LOCATION_BASE_PATH}/${locationCode}`)

export const GetEvents = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<EventModel>>(`${EVENT_BASE_PATH}/list`, filter)

export const GetEventByCode = (code: string) => axios.get<EventModel>(`${EVENT_BASE_PATH}/${code}`)

export const GetEventsPortal = () => axios.get<EventModel[]>(`${EVENT_BASE_PATH}`)

export const GetProducts = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<ProductModel>>(`${PRODUCT_BASE_PATH}/list`, filter)

export const GetProductByCode = (code: string) => axios.get(`${PRODUCT_BASE_PATH}/${code}`)

export const GetPersonalLocationsByProductCode = (code: string) =>
  axios.get(`${PRODUCT_BASE_PATH}/${code}${LOCATION_BASE_PATH}`)

export const GetProductsBookingByEvent = (eventCode: string) =>
  axios.get<ProductModel[]>(`${PRODUCT_BASE_PATH}/${eventCode}/list`)

export const GetISOCountries = () => axios.get<ISOCountryModel[]>(`${CUSTOMER_BASE_PATH}/country`)
export const GetCountryStates = (filters: any) =>
  axios.post<ISOCountryModel[]>(`${CUSTOMER_BASE_PATH}/state`, {
    filters,
  })

export const GetVoucherTemplateList = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<VoucherModel>>(`${VOUCHERS_BASE_PATH}/template/list`, filter)

// BOOKING
export const GetBookingList = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<BookingModel>>(`${BOOKING_BASE_PATH}/list`, filter)
export const GetBookingByCode = (code: string) =>
  axios.get<BookingModel>(`${BOOKING_BASE_PATH}/${code}`)
export const PostBooking = (data: PortalBookingModelCreateParams) =>
  axios.post(BOOKING_BASE_PATH, data)

export const AddBooking = (data: BookingModelCreateParams) => axios.post(BOOKING_BASE_PATH, data)

export const PutBooking = (data: PortalBookingModelCreateParams, bookingCode: string) =>
  axios.put(`${BOOKING_BASE_PATH}/${bookingCode}`, data)

export const UpdateBooking = (data: BookingModelCreateParams, bookingCode: string) =>
  axios.put(`${BOOKING_BASE_PATH}/${bookingCode}`, data)

export const CancelBooking = (bookingCodes: string[], reason: string) =>
  axios.post<unknown>(`${BOOKING_BASE_PATH}/cancel`, {reason, codes: bookingCodes})

export const GetBookingProducts = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<BookingModel>>(`${BOOKING_PRODUCT_BASE_PATH}`, filter)

export const GetBookingDetail = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<BookingDetailModel>>('/bookings/detail', filter)

// Bulk Booking
export const PostBulkBooking = (data: BulkBookingModelCreateParams[]) =>
  axios.post(`${BOOKING_BASE_PATH}/bulk`, data)

// RESERVATION

export const GetReservationList = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<ReservationPortalModel>>(`${RESERVATION_BASE_PATH}/list`, filter)

export const GetReservationByCode = (code: string) =>
  axios.get<ReservationPortalModel>(`${RESERVATION_BASE_PATH}/${code}`)
export const SearchReservationItems = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<ReservationItemModel>>(`reservation/details`, filter)

export const PostReservation = (data: ReservationModelSeatedAndNonSeatedCreateParams) =>
  axios.post(RESERVATION_BASE_PATH, data)

export const PutReservation = (data: ReservationCreateParams, reservationCode: string) =>
  axios.put(`${RESERVATION_BASE_PATH}/${reservationCode}`, data)
export const releaseReservation = (ReservationCodes: string[], reason: string) =>
  axios.post<unknown>(`${RESERVATION_BASE_PATH}/release`, {reason, codes: ReservationCodes})

export const GetProductsReservationByEvent = (eventCode: string) =>
  axios.get<ProductModel[]>(`/product${RESERVATION_BASE_PATH}/${eventCode}`)

export const GetLocationsReservationByEvent = (eventCode: string) =>
  axios.get<LocationModel[]>(`/location${RESERVATION_BASE_PATH}/${eventCode}`)

export const GetAvailablReservationSeatMap = (locationCode: string, eventCode: string) =>
  axios.post(`/location/${locationCode}/reservation-availability`, {
    eventCode,
  })

//NEW PORTAL SEATMAP AVAILABILITY API
export const GetSeatMapAvailabilityByProduct = (
  locationCode: string,
  eventCode: string,
  productCode: string
) =>
  axios.post(`/location/${locationCode}/availability`, {
    productCode,
    eventCode,
  })

export const GetReservationDetail = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<ReservationDetailsModel>>('/reservation/details', filter)

// WIDGET

export const GetWidgets = (widgetCodes: (keyof WidgetModel)[], eventCode: string) =>
  axios.post<WidgetModel>(`/widget`, {
    event: eventCode,
    widgets: widgetCodes,
  })

export const GetPoratlWidgets = (widgetCodes: (keyof PortalWidgetModal)[], eventCode: string) =>
  axios.post<PortalWidgetModal>(`/widget`, {
    event: eventCode,
    widgets: widgetCodes,
  })

export const CreateCustomerImport = (mapping: string[], csv: File, hasHeaders: boolean = true) => {
  const formData = new FormData()
  formData.append('file', csv)
  mapping.forEach((map, index) => {
    formData.append(`map[${index}]`, map)
  })
  formData.append('hasHeaders', String(hasHeaders))
  return axios.post<any>(`/import/customer-import`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

import {SetStateAction, useCallback, } from 'react'
import {FormStep} from '../BookingWizard'
import {ResetSignUpPassword, } from '../../../../customer-portal/redux/CustomerPortalCRUD'
// import {useCustomerAuth} from '../../../../customer-portal/hooks/useCustomerAuth'
// import {useHistory} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {actions} from '../../../../customer-portal/redux/CustomerPortalRedux'
import { PasswordResetFormValues, PasswordResetForm} from '../../forms/PasswordResetForm'
import { useStepperState } from '../../../pages/StepperStateContext'

export interface BookingWizardResetInitialInformationProps {
  setCurrentStep: (value: SetStateAction<FormStep>) => void
  clickedPrevious: boolean
  setPrevious: (value: boolean) => void
}

export const BookingWizardResetInitialInformation = ({
  clickedPrevious,
  setPrevious,
  setCurrentStep,
}: BookingWizardResetInitialInformationProps) => {
  const {setResetSubmit} = useStepperState()
  // const _auth = useCustomerAuth()
  // const history = useHistory()
  // useEffect(() => {
  //   if (!_auth) {
  //     history.replace('/auth/retail')
  //   }
  // }, [_auth, history])


  const dispatch = useDispatch()
  const handleOnSubmit = useCallback(
    async (formValues: PasswordResetFormValues) => {
      const response = await ResetSignUpPassword(formValues.newPassword)
      dispatch(actions.auth.login(response.data.token))
      setCurrentStep(FormStep.PAYMENT)
      setResetSubmit(false)
    },
    [dispatch, setCurrentStep, setResetSubmit]
  )

  return (
    <>
      <div
        className='bg-body rounded shadow-sm p-10 p-lg-15'
        style={{maxWidth: '57vw', margin: 'auto'}}
      >
        <PasswordResetForm onSubmit={handleOnSubmit} clickedPrevious={clickedPrevious} setPrevious={setPrevious} setCurrentStep={setCurrentStep}/>
      </div>
    </>
  )
}
